import React, { useState, useEffect } from "react"
import "./ActNow.scss"
import { Navigate, useNavigate, useParams } from "react-router-dom"
import { Stack, useMediaQuery } from "@mui/material"
import EcoActionsContainer from "../Components/EcoActionsContainer/EcoActionsContainer"
import appapi from "../../../api/apiClient"
import CircularProgress from "@mui/material/CircularProgress"
import EWasteForm from "../Components/EWasteForm/EWasteForm"
import TextileWasteForm from "../Components/TextileWasteForm/TextileWasteForm"

import WorkShopForm from "../Components/WorkShopForm/WorkShopForm"
import { useDispatch, useSelector } from "react-redux"
import { setLoading } from "../../../redux/features/appSlice"
import PlasticWasteForm from "../Components/PlasticWasteForm/PlasticWasteForm"

export default function ActNow() {
  const isMobile = useMediaQuery("(max-width:800px)")
  const { locationid, scheduleid } = useParams()
  const dispatch = useDispatch()
  const [ecoAction, setEcoAction] = useState("")
  const navigate = useNavigate()
  const { isLoading } = useSelector(state => state.app)

  useEffect(() => {
    if (scheduleid) {
      dispatch(setLoading(true))
      getEcoActionDetails()
    }
  }, [scheduleid])

  const getEcoActionDetails = async () => {
    const response = await appapi.get(`webform/ecoCampaign/${scheduleid}`)
    if (response.ok) {
      const data = response.data
      dispatch(setLoading(false))
      if (data.status === "success") {
        setEcoAction(data.data)
        localStorage.setItem('/diz/brandLogo',data.data.brandLogo)
      } else {
        navigate("/")
      }
    } else {
      dispatch(setLoading(false))
      navigate("/")
    }
  }

  return (
    <EcoActionsContainer>
      {!isLoading && (
        <div className="actnowFormContainer" style={{ width: isMobile ? "100%" : "384px", marginLeft: "24px", marginRight: "24px" }}>
          {ecoAction && ecoAction?.actionType === "ewastecollection"       && <EWasteForm       scheduleid={scheduleid} locationid={locationid} ecoAction={ecoAction} />}
          {ecoAction && ecoAction?.actionType === "plasticwastecollection" && <PlasticWasteForm scheduleid={scheduleid} locationid={locationid} ecoAction={ecoAction} />}
          {ecoAction && ecoAction?.actionType === "textilewastecollection" && <TextileWasteForm       scheduleid={scheduleid} locationid={locationid} ecoAction={ecoAction} />}
          {ecoAction && ecoAction?.actionType === "formtemplate3" && <WorkShopForm scheduleid={scheduleid} ecoAction={ecoAction} locationid={locationid} />}
        </div>
      )}
    </EcoActionsContainer>
  )
}
