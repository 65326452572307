import facebook from '../assets/images/facebook.svg';
import youtube from '../assets/images/youtube.svg';
import linkedin from '../assets/images/linkedin.svg';
import twitter from '../assets/images/twitter.svg';
import instagram from '../assets/images/instagram.svg';
import consolidated from '../assets/images/consolidated.svg';
import drivenmarketing from '../assets/images/drivenmarketing.svg';
import quickonboading from '../assets/images/quickonboading.svg';
import appapi from '../api/apiClient';

export const activateStatusType = {
    active: 'activate',
    deActivated: 'deactivate',
    invite: 'invite'
}

export const socialImgArr = [
    {
        iconName: facebook,
        link: 'https://www.facebook.com/doitzero',
        name: 'facebook'
    },
    {
        iconName: linkedin,
        link: 'https://in.linkedin.com/company/doitzero',
        name: 'linkedin'
    },
    {
        iconName: twitter,
        link: 'https://twitter.com/doit_zero',
        name: 'twitter'
    },
    {
        iconName: instagram,
        link: 'https://www.instagram.com/doitzero2050/',
        name: 'instagram'
    },
    {
        iconName: youtube,
        link: 'https://www.youtube.com/channel/UCdUedjscC1dJ-nbzQWMBAgg',
        name: 'youtube'
    }
]

export const detailArr = [
    {
        logo: quickonboading,
        title: 'Quick & Easy Onboarding',
        subTitle: 'Set up your account with secure access'
    },
    {
        logo: drivenmarketing,
        title: 'Purpose driven Marketing',
        subTitle: 'Engage customers through eco-actions'
    },
    {
        logo: consolidated,
        title: 'Consolidated Reporting',
        subTitle: 'Get carbon impact reports for stakeholders'
    }
]

export const reportSelect = [
    {
        name: 'This month',
        value: 'monthly'
    },
    {
        name: 'This quarter',
        value: 'quarterly'
    },
    {
        name: 'This year',
        value: 'yearly'
    },
    {
        name: 'All time',
        value: 'alltimes'
    }
]

export const reportSelectAlternative = [
    {
        name: 'Monthly',
        value: new Date().getFullYear()
    },
    {
        name: 'Quarterly',
        value: 1
    },
    {
        name: 'Annually',
        value: 0
    }
]

export const analyticData = [
    {
        name: 'Campaigns executed',
        data: '0',
        slides: null,
        type: 'campaignsExecuted'
    },
    {
        name: 'Ongoing campaigns',
        data: '0',
        slides: null,
        type: 'campaignsOngoing'
    },
    {
        name: 'Unique Users Engaged',
        data: '0',
        slides: null,
        type: 'uniqueEngagedUsers'
    },
    {
        name: 'Eco-actions Taken',
        data: '0',
        slides: null,
        type: 'ecoActionsTaken'
    },
    {
        name: 'Eco-points Awarded',
        data: '0',
        slides: null,
        type: 'ecoPointsAwarded'
    },
    {
        name: 'Trees planted',
        data: '0',
        slides: null,
        type: 'treesPlanted'
    },
    {
        name: 'Total GHG Impact',
        data: '0',
        slides: null,
        type: 'totalGHGImpact'
    },
]

export const barDataType = [
    {
        name: 'Bar Chart',
        type: 'bar'
    },
    {
        name: 'Line Chart',
        type: 'line'
    }
]

// export const roleAccessData = [
//     {
//         "roleId": 1,
//         "roleName": "Super Admin",
//         initPage: 'Eco Initiatives',
//         access: 'All'
//     },
//     {
//         "roleId": 2,
//         "roleName": "Admin",
//         initPage: 'Eco Initiatives',
//         access: 'All'
//     },
//     {
//         "roleId": 3,
//         "roleName": "Business Owner",
//         initPage: 'Eco Initiatives',
//         access: 'ExceptIntegration'
//     },
//     {
//         "roleId": 4,
//         "roleName": "Marketing",
//         initPage: 'Eco Initiatives',
//         access: 'ExceptIntegration'
//     },
//     {
//         "roleId": 5,
//         "roleName": "Developer",
//         initPage: 'Eco Initiatives',
//         access: 'ExceptIntegration'
//     },
//     {
//         "roleId": 6,
//         "roleName": "Finance",
//         initPage: 'Payments',
//         access: 'ExceptEcoAndInte'
//     },
//     {
//         "roleId": 7,
//         "roleName": "Human Resource",
//         initPage: 'Analytics',
//         access: 'ExceptEcoAndInte'
//     }
// ]

export const routingList = [
    {
        name: 'userManagement',
        moduleName: 'user_management'
    },
    {
        name: 'integrations',
        moduleName: 'integrations'
    },
    {
        name: 'analytics',
        moduleName: 'reporting_analytics'
    },
    {
        name: 'ecoInitiatives',
        moduleName: 'eco_initiatives'
    },
    {
        name: 'payments',
        moduleName: 'payments'
    }
]

export const chartFilterData = [
    {
        name: 'Unique Users Engaged',
        value: 'uniqueuser'

    },
    {
        name: 'Trees planted',
        value: 'treeplanted'
    },
    {
        name: 'Eco-points Awarded',
        value: 'ecopoints'
    }
]


export const months = [
    {
        value: 1,
        month: 'Jan',
        count: 0
    },
    {
        value: 2,
        month: 'Feb',
        count: 0
    },
    {
        value: 3,
        month: 'Mar',
        count: 0
    },
    {
        value: 4,
        month: 'Apr',
        count: 0
    },
    {
        value: 5,
        month: 'May',
        count: 0
    },
    {
        value: 6,
        month: 'Jun',
        count: 0
    },
    {
        value: 7,
        month: 'Jul',
        count: 0
    },
    {
        value: 8,
        month: 'Aug',
        count: 0
    },
    {
        value: 9,
        month: 'Sept',
        count: 0
    },
    {
        value: 10,
        month: 'Oct',
        count: 0
    },
    {
        value: 11,
        month: 'Nov',
        count: 0
    },
    {
        value: 12,
        month: 'Dec',
        count: 0
    },
]

export const quarter = [
    {
        name: 'Q1',
        quarter: 1,
        count: 0
    },
    {
        name: 'Q2',
        quarter: 2,
        count: 0
    },
    {
        name: 'Q3',
        quarter: 3,
        count: 0
    },
    {
        name: 'Q4',
        quarter: 4,
        count: 0
    },
]

export const uploadFile = async (file, type) => {
    return new Promise(async (resolve, reject) => {
      const ext = file.name.split(".")[1]
      const key = `${type}/${Date.now()}.${ext}`
      const signedUrlResult = await appapi.post("/s3", {
        fileName: key,
        contentType: file.type,
        type: "app",
      })
      if (signedUrlResult.ok) {
        const signedUrl = signedUrlResult.data.url
        fetch(signedUrl, { method: "PUT", body: file })
          .then(res => {
            if (res.ok) {
              resolve(signedUrlResult.data.key)
            } else {
              reject(null)
            }
          })
          .catch(error => {
            reject(null)
            console.log("There has been a problem with fetching signed url: " + error.message)
          })
      } else {
      }
    })
  }

  export const uploadFileWithoutToken = async (file, type) => {  try {
      const ext = file.name.split(".")[1]
      const key = `${type}/${Date.now()}.${ext}`
          const signedUrlResult = await appapi.post("/webform/s3Fileupload", {
            fileName: key,
            contentType: file.type,
            type: "app",
          })
          return signedUrlResult.data.key
      }
      catch (e) { 
        throw new Error(e.message) 
        console.log("There has been a problem with fetching signed url: " + e.message); 
      }

      /*
      if (signedUrlResult.ok) {
        const signedUrl = signedUrlResult.data.url
        fetch(signedUrl, { method: "PUT", body: file })
          .then(res => {
            if (res.ok) {
              resolve(signedUrlResult.data.key)
            } else {
              reject(null)
            }
          })
          .catch(error => {
            reject(null)
            console.log("There has been a problem with fetching signed url: " + error.message)
          })
      } else {
      }*/
  }