import "./VerifyMobile.scss"
import EcoActionsContainer from "../Components/EcoActionsContainer/EcoActionsContainer"
import { Button, FormControl, Select, Stack, Typography, useMediaQuery, MenuItem, ListItemText, Box, InputAdornment } from "@mui/material"
import * as yup from "yup"
import { Form, Formik } from "formik"
import FormTextField from "../../../components/FormTextField"
import { buttonStyleForWebForm } from "../../../utils/commonStyle"
import { useRef, useState } from "react"
import india from "../../../assets/images/india.png"
import { useLocation, useNavigate } from "react-router-dom"
import { uploadFileWithoutToken } from "../../../utils/commonUtils"
import appapi from "../../../api/apiClient"
import { showmessage } from "../../../utils/toastr"
import { useDispatch } from "react-redux"
import { setLoading } from "../../../redux/features/appSlice"

const validationSchema = yup.object({
  mobile: yup
    .string()
    .required()
    .min(10, "Mobile Number must be 10 digits")
    .matches(/^[0-9]*$/, "Mobile Number should be only numbers")
    .label("Mobile Number"),
})

export default function VerifyMobile() {
  const formRef = useRef()
  const navigate = useNavigate()
  const dispatch = useDispatch()
  const { state } = useLocation()
  const isMobile = useMediaQuery("(max-width:800px)")
  const { data, actionType, image } = state

  const submit = async values => {
    dispatch(setLoading(true))
    const response = await appapi.post("webform/login", values)
    if (response.ok) {
      const res = response.data
      dispatch(setLoading(false))
      if (res.status === "success") {
        navigate(`/webform/verifyOtp`, {
          state: {
            data: data,
            otpId: res.data.otpId,
            mobile: values.mobile,
            actionType,
            image,
          },
        })
      } else {
        showmessage(res.message, "error")
      }
    }
  }

  const skip = async () => {
    dispatch(setLoading(true))
    if (actionType === "ewastecollection") {
      submitEWaste()
    }
    if (actionType === "plasticwastecollection") {
      submitPlasticWaste()
    }
    if (actionType === "textilewastecollection") {
      submitTextileWaste()
    }
    if (actionType === "formtemplate3") {
      submitInteractiveWorkshop()
    }
  }

  const submitEWaste = async () => {
    const response = await appapi.post("webform/eWaste/0", data)
    if (response.ok) {
      const res = response.data
      dispatch(setLoading(false))
      if (res.status === "success") {
        navigate(`/webform/congrats`, {
          state: {
            points: res.data.points,
            content: res.data.content,
            digiCertiUrl : res.data.digiCertiUrl,
            image,
            actionType,
            isSkippedVerification: true
          },
        })
      } else {
        showmessage(res.message, "error")
      }
    }
  }

  const submitPlasticWaste = async () => {
    const response = await appapi.post("webform/plasticWaste/0", data)
    if (response.ok) {
      const res = response.data
      dispatch(setLoading(false))
      if (res.status === "success") {
        navigate(`/webform/congrats`, {
          state: {
            points: res.data.points,
            content: res.data.content,
            digiCertiUrl : res.data.digiCertiUrl,
            image,
            actionType,
            isSkippedVerification: true
          },
        })
      } else {
        showmessage(res.message, "error")
      }
    }
  }


  const submitTextileWaste = async () => {
    const response = await appapi.post("webform/textileWaste/0", data)
    if (response.ok) {
      const res = response.data
      dispatch(setLoading(false))
      if (res.status === "success") {
        navigate(`/webform/congrats`, {
          state: {
            points: res.data.points,
            content: res.data.content,
            digiCertiUrl : res.data.digiCertiUrl,
            image,
            actionType,
            isSkippedVerification: true
          },
        })
      } else {
        showmessage(res.message, "error")
      }
    }
  }

  const submitInteractiveWorkshop = async () => {
    const response = await appapi.post("webform/joinEvent/0", data)
    if (response.ok) {
      const res = response.data
      dispatch(setLoading(false))
      if (res.status === "success") {
        navigate(`/webform/congrats`, {
          state: {
            points: res.data.points,
            content: res.data.content,
            digiCertiUrl : res.data.digiCertiUrl,
            image: null,
            actionType,
            isSkippedVerification: true
          },
        })
      } else {
        showmessage(res.message, "error")
      }
    }
  }

  return (
    <EcoActionsContainer>
      <div className="verifyMobileFormContainer" style={{ width: isMobile ? "100%" : "384px", marginLeft: "24px", marginRight: "24px" }}>
        <Typography className="ff-Roboto fw-medium fs-32" color={"#3787C9"}>
          Proceed and Earn Ecopoints
        </Typography>
        <Typography className="ff-Roboto fw-regular fs-16 mt-16" color={"#737179"}>
          Enter your mobile number
        </Typography>
        <Formik
          innerRef={formRef}
          enableReinitialize={true}
          initialValues={{
            mobile: "",
          }}
          validationSchema={validationSchema}
          onSubmit={values => submit(values)}
        >
          {({ handleSubmit, values, handleChange, errors, touched, handleBlur, isValid, dirty, setFieldValue }) => (
            <Form noValidate autoComplete="off">
              <Stack flex={1} style={{ marginTop: 80 }} direction={"row"}>
                <Stack style={{ width: 80, marginRight: 16 }}>
                  <FormControl variant="filled">
                    <Select
                      labelId="demo-multiple-checkbox-label"
                      id="demo-multiple-checkbox"
                      placeholder="Select"
                      disabled
                      value={values["zone"] || []}
                      displayEmpty
                      renderValue={value => {
                        return (
                          <Box sx={{ display: "flex" }}>
                            <img alt="dizLogo" src={india} />
                          </Box>
                        )
                      }}
                      sx={{
                        "#demo-multiple-checkbox": {
                          padding: "0px!important",
                          paddingLeft: "12px!important",
                          backgroundColor: "#FFFFFF!important",
                          height: "56px",
                          display: "flex",
                          alignItems: "center",
                        },
                      }}
                      style={{ backgroundColor: "white" }}
                    >
                      {[].map(zone => (
                        <MenuItem key={zone.collectionZoneId} value={zone.collectionZoneId}>
                          <ListItemText primary={zone.collectionZoneName} />
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Stack>
                <Stack flex={1}>
                  <FormTextField
                    adornment={{ startAdornment: <InputAdornment position="start">+91</InputAdornment> }}
                    inputProps={{ maxLength: 10 }}
                    inputStyle={{ backgroundColor: "white" }}
                    variant="filled"
                    placeholder="Mobile Number"
                    name="mobile"
                    style={{ fontSize: 14, backgroundColor: "#FFFFFF" }}
                  />
                </Stack>
              </Stack>

              <Button disabled={!(dirty && isValid)} sx={{ ...buttonStyleForWebForm, width: "100%", mt: "36px" }} type="submit">
                CONTINUE
              </Button>
              <Stack alignItems={"center"}>
                <Button sx={{ mt: "24px", color: "#ADADAD", textTransform: "capitalize" }} type="button" onClick={() => skip()}>
                  Skip
                </Button>
              </Stack>
              <Stack direction="row" className="mt-16" justifyContent="center"></Stack>
            </Form>
          )}
        </Formik>
      </div>
    </EcoActionsContainer>
  )
}
