import React, { useState, useRef, useEffect, createRef } from "react"
import "./EcoActionShare.scss"
import { useLocation, useNavigate, useParams } from "react-router-dom"
import { Button, IconButton, Stack, Typography, useMediaQuery } from "@mui/material"
import ShareIcon from "@mui/icons-material/Share"
import EcoActionsContainer from "../Components/EcoActionsContainer/EcoActionsContainer"
import { buttonStyle1 } from "../../../utils/commonStyle"
import diz from "../../../assets/images/diz.svg"
import { useScreenshot } from "../../../components/Screenshot"
import { showmessage } from "../../../utils/toastr"
import { LazyLoadImage } from "react-lazy-load-image-component"
import CropIcon from "@mui/icons-material/Crop"
import CropImage from "../Components/CropImage/CropImage"

export default function EcoActionShare() {
  const isMobile = useMediaQuery("(max-width:800px)")
  const { state } = useLocation()
  const { image, certificate } = state
  const navigate = useNavigate()
  const [previewImageUrl, setPreviewImageUrl] = useState("")
  const ref = createRef(null)
  const [openModal, setOpenModal] = React.useState(false)
  const [croppedImage, setCroppedImage] = useState("")
  const [showCrop, setShowCrop] = useState(true)
  const [imageurl, takeScreenshot] = useScreenshot({ quality: 1.0 })

  useEffect(() => {
    if (image) {
      const imageObj = new Image()
      imageObj.src = URL.createObjectURL(image)
      imageObj.addEventListener("load", () => {
        let objUrl = URL.createObjectURL(image)
        setPreviewImageUrl(objUrl)
      })
    }
  }, [image])

  useEffect(() => {
    if (imageurl) {
      share(imageurl)
    }
  }, [imageurl])

  useEffect(() => {
    if (!showCrop) {
      takeScreenshot(ref.current)
    }
  }, [showCrop])

  const dataURLtoFile = dataurl => {
    var arr = dataurl.split(","),
      mime = arr[0].match(/:(.*?);/)[1],
      bstr = atob(arr[arr.length - 1]),
      n = bstr.length,
      u8arr = new Uint8Array(n)
    while (n--) {
      u8arr[n] = bstr.charCodeAt(n)
    }
    return new File([u8arr], "share.png", { type: mime })
  }

  const share = async imageurl => {
    if (navigator.share) {
      const response = await navigator.share({ files: [dataURLtoFile(imageurl)] })
      setShowCrop(true)
      takeScreenshot(null)
    } else {
      setShowCrop(true)
      takeScreenshot(null)
      showmessage("Share option is not available", "error")
    }
  }

  return (
    <EcoActionsContainer>
      <div className="ecoActionShareContainer" style={{ width: isMobile ? "100%" : "384px", marginLeft: "24px", marginRight: "24px" }}>
        <Stack ref={ref}>
        { certificate == false &&
         <div className="logoShareImg" style={{ marginLeft: "8px" }}>
            <img className="ecoShareLogo" alt="dizLogo" src={diz} />
          </div>}
          
          <div className="ecoActionShareCard">
            <div style={{ width: "100%", height: "auto", display: "flex", alignItems: "center", justifyContent: "center", position: "relative" }}>
              <LazyLoadImage
                alt={"preview"}
                height={"auto"}
                src={croppedImage ? croppedImage : previewImageUrl} // use normal <img> attributes as props
                width={"100%"}
                style={{ width: "100%", height: "auto", objectFit: "contain", borderRadius: "8px" }}
              />
              {showCrop && (
                <IconButton onClick={() => setOpenModal(true)} size="small" style={{ position: "absolute", bottom: 0, right: 0, backgroundColor: "#F2F9FF" }}>
                  <CropIcon />
                </IconButton>
              )}
            </div>
            { certificate == false &&
            <Stack alignItems={"center"} className="mt-12 mb-8">
              <Typography className="ff-Roboto fw-medium fs-14" sx={{ color: "#404040", textAlign: "center" }}>
                Feeling proud of taking an eco-action on DIZ!
              </Typography>
              <Typography className="ff-Roboto fw-regular fs-12 mt-4" sx={{ color: "#404040", textAlign: "center" }}>
                Join me in a journey for a positive impact.
              </Typography>
            </Stack>}
          </div>
        </Stack>
        <Button onClick={() => setShowCrop(false)} sx={{ ...buttonStyle1, mt: "24px", textTransform: "none", width: "100%" }} type="button" endIcon={<ShareIcon />}>
          Share on Social Media
        </Button>
        <CropImage
          imageUri={previewImageUrl}
          open={openModal}
          onClose={imageUri => {
            setOpenModal(false)
            if (imageUri) {
              setCroppedImage(imageUri)
            }
          }}
        />
      </div>
    </EcoActionsContainer>
  )
}
